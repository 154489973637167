<template>
  <div class="find-password">
    <div class="content">
      <div class="tip">
        <svg-icon icon-class="find-password-tip" class="svg" />
        请输入你需要找回登录密码的账户名
      </div>
      <div class="input-account">
        <el-input v-model="accountName" style="width: 300px;" placeholder="请输入登录账号" />
        <div style="margin-top: 20px;">
          <el-button type="primary" style="width: 300px;" @click="next">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserIsExist } from '@/api/user'
import { ElMessage } from 'element-plus'
export default {
  name: 'FindPassword',
  data() {
    return {
      accountName: ''
    }
  },
  methods: {
    getUserIsExist() {
      getUserIsExist(this.accountName.trim()).then(res => {
        if (res.meta.status === 200) {
          this.$router.push('/personal-info/change-find-type?account=' + this.accountName)
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    next() {
      if (this.accountName.length === 0) {
        ElMessage.error('请填写账户名')
        return
      }
      this.getUserIsExist()
    }
  }
}
</script>

<style lang="less" scoped>
  .find-password{
    height: 100%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    .content {
      display: flex;
      font-size: 12px;
      color: #313a46;
      padding-top: 72px;
      .tip {
        font-size: 14px;
        height: 14px;
        .svg {
          margin-right: 10px;
        }
      }
      .input-account {
        height: 40px;
        margin-top: 32px;
      }
    }
  }
</style>
